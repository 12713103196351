<template>
  <div>
    <img class="transform translate-y-1 translate-x-1 invisible" src="@/assets/voucher-event.svg" alt="" />
    <img class="absolute z-40 top-0 opacity-80 transform translate-y-1 translate-x-1" src="@/assets/voucher-expired.svg"
      alt="" />
    <div class="absolute z-30 top-1/2 sm:left-12 left-8 inline-block transform -translate-y-1/2">
      <div class="flex items-center">
        <img :src="tIm" class="w-14 h-14 sm:w-20 sm:h-20 rounded-full" alt="" />
        <div class="ml-8 text-xs">
          <h2 class="sm:text-lg text-sm font-semibold truncate sm:w-64 w-40">{{tTt}}</h2>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-calendar" size="lg" />
            </span>{{$moment(tDt).format("dddd, D MMMM YYYY")}}</p>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-solid fa-location-dot" size="lg" />
            </span>{{tLc}}</p>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-clock" size="lg" />
            </span>{{tTm}} WIB</p>
        </div>
      </div>
    </div>
    <div class="absolute z-50 top-1/2 transform -translate-y-1/2 sm:left-12 left-9 flex items-center justify-center w-20 h-20">
      <p class="font-bold text-white sm:text-lg text-base">Expired</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardPastTicket',
  props: {
    tIm: {
      type: String,
      required: true,
    },
    tTt: {
      type: String,
      required: true,
    },
    tDt: {
      type: String,
      required: true,
    },
    tTm: {
      type: String,
      required: true,
    },
    tLc: {
      type: String,
      required: true,
    },
    tLc: {
      type: String,
      required: true,
    },
  },
  components: {
  },
  methods: {
  }
}
</script>